import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {TextField, Typography, Button, Hidden,} from '@material-ui/core';
import {Card, CardHeader, CardContent} from '@material-ui/core';
import {Divider, Paper} from '@material-ui/core';

import ModalCompartilhamento from './ModalCompartilhamento.js';

import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

const styles = {
    menuButton: {
        marginLeft: 0,
        marginRight: 0,
    },
    labelForm: {
        width: '120px'
    },
    label: {
        width: '100%',
        paddingTop: '2.3%',
        textTransform: 'initial',
        verticalAlign: 'middle',
        fontSize: '0.85rem',
        fontWeight: 500,
        color: '#757575'
    },
};

class PesquisaTextual extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            operadorOu: '',
            operadorE: '',
            operadorExpressaoExata: '',
            operadorNaoContem: '',
            ementa: '',
            dispositivo: ''
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            operadorOu: props.operadorOu,
            operadorE: props.operadorE,
            operadorExpressaoExata: props.operadorExpressaoExata,
            operadorNaoContem: props.operadorNaoContem,
            ementa: props.ementa,
            dispositivo: props.dispositivo,
            urlCompartilhamento: props.urlCompartilhamento
        });
    }

    atualizaValoresDoCampo = (campo, valor) => {
        this.setState({
            [campo]: valor,
        });
        this.props.atualizaValorDosCamposDePesquisaTextual(campo, valor);
    }

    handleChange = name => event => {
        this.atualizaValoresDoCampo(name, event.target.value);
    };

    handleKeyPress = name => event => {
        if (event.key === 'Enter') {
            this.atualizaValoresDoCampo(name, event.target.value);
            this.props.pesquisar();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.operadorOu !== this.props.operadorOu ||
            nextProps.operadorE !== this.props.operadorE ||
            nextProps.operadorExpressaoExata !== this.props.operadorExpressaoExata ||
            nextProps.operadorNaoContem !== this.props.operadorNaoContem ||
            nextProps.ementa !== this.props.ementa ||
            nextProps.urlCompartilhamento !== this.props.urlCompartilhamento ||
            nextProps.dispositivo !== this.props.dispositivo;
    }

    render() {
        const {classes} = this.props;

        return (
            <Card className={window.innerWidth > 1000 ? 'altura-card-padrao' : 'altura-automatica'}>
                <CardHeader title={<Typography variant='h5' style={{color: 'white'}}>Pesquisa Livre</Typography>}
                            disableTypography={true} style={{backgroundColor: '#4f83cc'}}/>
                <Divider/>
                <CardContent>
                    <div style={{flexDirection: 'column', alignSelf: 'center'}}>
                        <TextField
                            id="campoTxtOperadorE"
                            label="Contendo as palavras (e)"
                            placeholder="Digite aqui"
                            type="search"
                            value={this.state.operadorE || ""}
                            onChange={this.handleChange('operadorE')}
                            onKeyPress={this.handleKeyPress('operadorE')}
                            autoFocus
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                        <TextField
                            id="campoTxtOperadorOu"
                            label="Qualquer das palavras (ou)"
                            placeholder="Digite aqui"
                            type="search"
                            value={this.state.operadorOu || ""}
                            onChange={this.handleChange('operadorOu')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                        <TextField
                            id="campoTxtOperadorNaoContem"
                            label="Sem conter as palavras (não)"
                            placeholder="Digite aqui"
                            type="search"
                            value={this.state.operadorNaoContem || ""}
                            onChange={this.handleChange('operadorNaoContem')}
                            onKeyPress={this.handleKeyPress('operadorNaoContem')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                        <TextField
                            id="campoTxtEmenta"
                            label="Palavras na ementa (e)"
                            placeholder="Digite aqui"
                            type="search"
                            value={this.state.ementa || ""}
                            onChange={this.handleChange('ementa')}
                            onKeyPress={this.handleKeyPress('ementa')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                        <TextField
                            id="campoTxtDispositivo"
                            label="Palavras no dispositivo (e)"
                            placeholder="Digite aqui"
                            type="search"
                            value={this.state.dispositivo || ""}
                            onChange={this.handleChange('dispositivo')}
                            onKeyPress={this.handleKeyPress('dispositivo')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </div>
                    <Hidden xsDown>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            width: '100%',
                            marginTop: '60px'
                        }}>
                            <div>
                                <Button classes={{label: classes.labelForm}} style={{margin: '10px'}}
                                        onClick={this.props.pesquisar.bind(this, undefined, undefined)}
                                        variant="contained" color="primary" size="large">
                                    <Search/>
                                    Pesquisar
                                </Button>
                                <Button classes={{label: classes.labelForm}} style={{margin: '10px'}}
                                        onClick={this.props.limparCampos} variant="contained" color="primary"
                                        size="large">
                                    <Clear/>
                                    Limpar
                                </Button>
                                <ModalCompartilhamento
                                    tituloDialogo="Compartilhar Pesquisa"
                                    urlCompartilhamento={this.props.urlCompartilhamento}
                                    compartilhar={this.props.compartilhar}
                                />
                            </div>
                        </div>
                    </Hidden>
                </CardContent>
            </Card>
        );
    }
}


export default withStyles(styles)(PesquisaTextual);